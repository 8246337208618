import request from "@/utils/request";

export function getUserNewsList(data) { //分页获取用户消息数据
    return request({
      url: "/news/user/page/list",
      method: "get",
      params: data,
    });
  }

  export function getNewsReadNews(data) { //查看消息（已读）
    return request({
      url: "/news/readNews",
      method: 'put',
      data
    });
  }

  export function getUserPageList(data) { //分页获取用户告警数据
    return request({
      url: "/alert/user/page/list",
      method: "get",
      params: data,
    });
  }
  export function getReadAlert(data) { //查看告警消息（已读）
    return request({
      url: "/alert/readAlert",
      method: 'put',
      data
    });
  }

  export function getMessageCenterUnread(data) { //导航栏-消息中心-未读数量
    return request({
      url: "/index/message/center/unread",
      method: "get",
      params: data,
    });
  }
  export function getMessageCenterNews(data) { //导航栏-消息中心-平台消息
    return request({
      url: "/index/message/center/news/list",
      method: "get",
      params: data,
    });
  }
  export function getMessageCenterAlert(data) { //导航栏-消息中心-告警信息
    return request({
      url: "/index/message/center/alert/list",
      method: "get",
      params: data,
    });
  }