import request from "@/utils/request";
// plot/addPlot
export function addPlot(data) {
  return request({
    url: "/plot/addPlot",
    method: "post",
    data,
  });
}
// plot/delPlot
export function delPlot(data) {
  return request({
    url: "/plot/delPlot",
    method: "delete",
    params: data,
  });
}

// plot/editPlot
export function editPlot(data) {
  return request({
    url: "/plot/editPlot",
    method: "post",
    data,
  });
}

// plot/getPlotList
export function getPlotList(data) {
  return request({
    url: "/plot/getPlotList",
    method: "get",
    params: data,
  });
}
// plot/v2/getLngAndLat
export function getLngAndLat(data) {
  return request({
    url: "/plot/v2/getLngAndLat",
    method: "get",
    params: data,
  });
}
// plot/all/list
export function getPlotAllList(data) {
  return request({
    url: "/plot/all/list",
    method: "get",
    params: data,
  });
}
