import request from '@/utils/request'
// sysFeedback/doFeedBack'
export function getFinanceList(data) {
    return request({
        url: '/finance/getFinanceList',
        method: 'get',
        params: data

    })
}
//finance/getSumFinace
export function getSumFinace(data) {
    return request({
        url: '/finance/getSumFinace',
        method: 'get',
        params: data

    })
}