import request from '@/utils/request'
// GET /operator/getOperatorList
export function getOperatorList(data) {
    return request({
        url: '/operator/getOperatorList',
        method: 'get',
        params: data

    })
}
// 、、/operator/add
export function add(data) {
    return request({
        url: '/operator/add',
        method: 'post',
        data

    })
}

// editOperator
export function editOperator(data) {
    return request({
        url: '/operator/editOperator',
        method: 'post',
        data

    })
}

export function editOperatorStatus(data) {
    return request({
        url: '/operator/editOperatorStatus',
        method: 'post',
        data

    })
}
// delOperator

export function delOperator(data) {
    return request({
        url: '/operator/delOperator',
        method: 'delete',
        params: data
    })
}

// getOperatorSelect
export function getOperatorSelect(data) {
    return request({
        url: '/operator/getOperatorSelect',
        method: 'get',
        params: data
    })
}

export function getTixianRecods(data) {
    return request({
        url: '/operator/getCashRecord',
        method: 'get',
        params: data
    })
}
/**
 * lxb 获取累计分成金额
 * @param data
 */
export function getAccumulatedAmount(data) {
    return request({
        url: '/operator/getAccumulatedAmount',
        method: 'get',
        params: data
    })
}

/**
 * lxb 设置代理商分成比例
 * @param data
 */
export function setUpRoyaltyPercent(data) {
    return request({
        url: '/operator/setUpRoyaltyPercent',
        method: 'get',
        params: data
    })
}
export function getAllUser(data) {
    return request({
        url: '/operator/allUser',
        method: 'get',
        params: data
    })
}
export function getUserOperators(data) {
    return request({
        url: '/operator/getUserOperators',
        method: 'get',
        params: data
    })
}
// editOperator
export function setOperatorsForUser(data) {
    return request({
        url: '/operator/setOperatorsForUser',
        method: 'post',
        data

    })
}

// deleteOpertaors
export function deleteOpertaors(data) {
    return request({
        url: '/operator/deleteOpertaors',
        method: 'post',
        params: data

    })
}
