import request from '@/utils/request'
// addAdvert
export function addAdvert(data) {
    return request({
        url: '/sysFile/addAdvert',
        method: 'post',
        data
    })
}
// editAdvert
export function editAdvert(data) {
    return request({
        url: '/sysFile/edit',
        method: 'put',
        data
    })
}
export function addAdverts(data) {
    return request({
        url: '/advert/addAdverts',
        method: 'post',
        data
    })
}

// advert/delAdvert
export function delFile(data) {
    return request({
        url: '/sysFile/deleteById',
        method: 'delete',
        params: data
    })
}

// file/getFileList
export function getFileList(data) {
    return request({
        url: '/sysFile/getFileList',
        method: 'get',
        params: data
    })
}

