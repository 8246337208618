import request from '@/utils/request';
// port/addPort
export function addPort(data) {
  return request({
    url: '/port/addPort',
    method: 'post',
    data,
  });
}

export function delPort(data) {
  return request({
    url: '/port/delPort',
    method: 'delete',
    params: data,
  });
}

///port/editPort

export function editPort(data) {
  return request({
    url: '/port/editPort',
    method: 'post',
    data,
  });
}
export function switchPort(data) {
  return request({
    url: '/port/switchPort',
    method: 'post',
    params: data,
  });
}
// port/getPortList

export function getPortList(data) {
  return request({
    url: '/port/getPortList',
    method: 'get',
    params: data,
  });
}

export function allPort(data) {
  return request({
    url: '/port/allPort',
    method: 'post',
    params: data,
  });
}

export function getPortStats(data) {
  return request({
    url: '/port/stats',
    method: 'get',
    params: data,
  });
}

// 端口状态监控详情
export function getStatMonitorDetail(data) {
  return request({
    url: '/port/stat/monitor/detail',
    method: 'get',
    params: data,
  });
}

// 端口状态监控订单信息
export function getStatMonitorList(data) {
  return request({
    url: '/port/stat/monitor',
    method: 'get',
    params: data,
  });
}

// 端口状态监控订单信息
/**
 *
 * @param {orderNumber} 订单编号
 * @returns
 */
export function getStatMonitorOrder(data) {
  return request({
    url: '/port/stat/monitor/order',
    method: 'get',
    params: data,
  });
}

// 端口状态监控订单电池信息
/**
 *
 * @param {orderNumber} 订单编号
 * @returns
 */
export function getStatMonitorBattery(data) {
  return request({
    url: '/port/stat/monitor/battery',
    method: 'get',
    params: data,
  });
}

// 站桩枪数据
export function getPileTree(data) {
  return request({
    url: '/port/pile/plot/list',
    method: 'get',
    params: data,
  });
}
