//getCodesByParent'
import request from '@/utils/request'
// GET /sysCard/getCardList
export function getCodesByParent(data) {
    return request({
        url: '/getCodesByParent',
        method: 'get',
        params: data

    })
}

// 8/city/getProvinceList
export function getProvinceList(data) {
    return request({
        url: '/city/getProvinceList',
        method: 'get',
        params: data

    })
}

// city/getCityList
export function getCityList(data) {
    return request({
        url: '/city/getCityList',
        method: 'get',
        params: data

    })
}

//city/getPoltList'
export function getPoltList(data) {
    return request({
        url: '/city/getPoltList',
        method: 'get',
        params: data

    })
}
//plot/v2/plotList
export function getPoltListV2(data) {
    return request({
        url: '/plot/v2/plotList',
        method: 'get',
        params: data

    })
}