import request from '@/utils/request'

// 获取代理详情
export function accountDtail(operatorId) {
    return request({
        url: `/operator/v2/action/${operatorId}/detail`,
        method: 'get',
    })
}
//获取代理商分成者
export function accountChildrenList(query) {
    return request({
        url: `/v2/shareholders/action/page`,
        method: 'get',
        params: query
    })
}

//创建分成者
export function accountCreate(body) {
    return request({
        url: `/v2/shareholders/create`,
        method: 'post',
        data: body
    })
}

// 更新分成者状态
export function accountUpdate(body) {
    return request({
        url: `/v2/shareholders/status/update`,
        method: 'post',
        data: body
    })
}
//获取代理商分成者（无分页）
export function shareholders(operatorId) {
    return request({
        url: `/v2/shareholders/action/${operatorId}/list`,
        method: 'get',
    })
}
// 更新代理商分成
export function radioUpdate(body) {
    return request({
        url: `/v2/shareholders/update`,
        method: 'post',
        data: body
    })
}
