import request from '@/utils/request'
// chargingpile/getChargingPileList
export function getChargingPileList(data) {
    return request({
        url: '/chargingpile/getChargingPileList',
        method: 'get',
        params: data

    })
}
// chargingpile/addPile
export function addPile(data) {
    return request({
        url: '/chargingpile/addPile',
        method: 'post',
        data
    })
}
export function setPileRule(data){
    return request({
        url: '/chargingpile/setPileRule',
        method: 'get',
        params: data
    })
}
//chargingpile/editPile
export function editPile(data) {
    return request({
        url: '/chargingpile/editPile',
        method: 'post',
        data

    })
}

///chargingpile/statisticalPile
export function statisticalPile(data) {
    return request({
        url: '/chargingpile/statisticalPile',
        method: 'get',
        params: data

    })
}

export function getChargeTotal(data) {
    return request({
        url: '/chargingpile/getChargeTotal',
        method: 'post',
        data: data

    })
}

export function setQrIssued(data) {
    return request({
        url: '/chargingpile/qrIssued',
        method: 'get',
        params: data
    })
}