//getCodesByParent'
import request from '@/utils/request'
// POST /menu/addMenu
export function addMenu(data) {
    return request({
        url: '/menu/addMenu',
        method: 'post',
        data

    })
}

// DELETE /menu/delMenu
export function delMenu(data) {
    return request({
        url: '/menu/delMenu',
        method: 'delete',
        params: data

    })
}

// GET /menu/getMenuList
export function getMenuList(data) {
    return request({
        url: '/menu/getMenuList',
        method: 'get',
        params: data

    })
}

// GET /menu/getMenuTree
export function getMenuTree(data) {
    return request({
        url: '/menu/getMenuTree',
        method: 'get',
        params: data

    })
}

// GET /menu/getUserMenu
export function getUserMenu(data) {
    return request({
        url: '/menu/getUserMenu',
        method: 'get',
        params: data

    })
}
// POST /menu/updateMenu
export function updateMenu(data) {
    return request({
        url: '/menu/updateMenu',
        method: 'post',
        data

    })
}

// GET /menu/getMenuArr
export function getMenuArr(data) {
    return request({
        url: '/menu/getMenuCodeByRoleCode',
        method: 'get',
        params: data

    })
}