import request from "@/utils/request";

// 用户车辆列表信息
export const getUserCars = (params) =>
  request({ url: `/car/userCars`, method: "get", params });

// 运营用户修改车辆信息
export const modifyUserCar = (data) =>
  request({ url: `/car/modifyUserCar`, method: "post", data });

// 获取车辆品牌信息
export const getCarBrandList = () =>
  request({ url: `/car/brandList`, method: "get" });

// 查询系列车型
export const getCarList = (seriesId) =>
  request({ url: `/car/carList`, method: "get", params: { seriesId } });

// 查询品牌系列
export const getSeriesList = (brandId) =>
  request({ url: `/car/seriesList`, method: "get", params: { brandId } });
