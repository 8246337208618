import request from "@/utils/request";

export function getOperatorList(data) {
    return request({
      url: "/operator/getOperatorList",
      method: "get",
      params: data,
    });
  }

  
export function getNoticeList(data) { //分页获取公告数据
  return request({
    url: "/notice/page/list",
    method: "get",
    params: data,
  });
}

export function addNotice(data) { //添加公告
  return request({
    url: "/notice/addNotice",
    method: "post",
    data,
  });
}

// 站点信息
export function getMemberPlots(data) {
  return request({
    url: "/plot/getPlotList",
    method: "get",
    params: data,
  });
}

export function delNotice(data) {
  return request({
    url: "/notice/delNotice",
    method: 'delete',
    params: data
  });
}

export function disableNotice(data) {
  return request({
    url: "/notice/disableNotice",
    method: 'put',
    data
  });
}

export function updateNotice(data) {
  return request({
    url: "/notice/updateNotice",
    method: 'put',
    data
  });
}

export function viewNotice(data) {
  return request({
    url: "/notice/viewNotice",
    method: "get",
    params: data,
  });
}
