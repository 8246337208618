import request from '@/utils/request'
// GET /operator/getOperatorList
export function refundList(data) {
    return request({
        url: '/refund',
        method: 'get',
        params: data

    })
}

// 修改状态
export function editRefund(data) {
    return request({
        url: '/refund',
        method: 'put',
        params: data

    })
}
