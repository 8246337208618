import request from "@/utils/request";


export function getWalletList(data) {
    return request({
        url: "/wallet/bills/list",
        method: "post",
        data,
    });
}

export function getTotalList(data) {
    return request({
        url: "/wallet/bills/total",
        method: "post",
        data,
    });
}

export function getRewardGift(data) {
    return request({
        url: "/wallet/reward/gift",
        method: "post",
        data,
    });
}

export function getWithdraw(data) {
    return request({
        url: "/wallet/withdraw/"+data.tradeNo,
        method: "get",
        data,
    });
}

export function getBillsDownload(data) {
    return request({
        url: "/wallet/bills/list/download",
        method: "post",
        data,
    });
}