import request from '@/utils/request'
// promotion/getPromotionList'
export function getPromotionList(data) {
    return request({
        url: '/promotion/getPromotionList',
        method: 'get',
        params: data
    })
}

//POST /promotion/addPromotion
export function addPromotion(data) {
    return request({
        url: '/promotion/addPromotion',
        method: 'post',
        data
    })
}
// DELETE /promotion/delPromotion
export function delPromotion(data) {
    return request({
        url: '/promotion/delPromotion',
        method: 'delete',
        params: data
    })
}
//POST /promotion/updatePromotion
export function updatePromotion(data) {
    return request({
        url: '/promotion/updatePromotion',
        method: 'post',
        data
    })
}