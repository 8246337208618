import request from "@/utils/request";

// 修改线路调度策略
export const updateLineNodeDispatchPolicy = (params) =>
  request({
    url: `/line/updateLineNode/dispatchPolicy`,
    method: "put",
    params,
  });

// 运营商线路数据
export const getLineTree = (params) =>
  request({ url: `/line/tree`, method: "get", params });

// 线路统计
export const getLineStats = (params) =>
  request({ url: `/line/line/stats`, method: "put", params });

export function getLineAllList(data) {
  return request({
    url: "/line/all",
    method: "get",
    params: data,
  });
}

export function getOperatorList(data) {
  return request({
    url: "/operator/getOperatorList",
    method: "get",
    params: data,
  });
}

export function getPlotList(data) {
  return request({
    url: "/plot/getPlotList",
    method: "get",
    params: data,
  });
}

export function getAddLine(data) {
  return request({
    url: "/line/addLine",
    method: "post",
    data,
  });
}

export function updateItemLine(data) {
  return request({
    url: "/line/updateLine",
    method: "put",
    data,
  });
}

export function getSearchLine(data) {
  return request({
    url: "/line/page/list",
    method: "get",
    params: data,
  });
}


export function getAmmeterList(data) {
  //根据站点获取电表数据
  return request({
    url: "/ammeter/list",
    method: "get",
    params: data,
  });
}

export function delLineTableList(data) { //删除线路
  return request({
    url: "/line/delLine",
    method: 'delete',
    params: data
  });
}

// 线路节点-树形
export function getNodeTreeList(data) {
  //线路节点树形数据
  return request({
    url: "/line/node/tree",
    method: "get",
    params: data,
  });
}

export function addNodeTree(data) {
  //添加线路节点
  return request({
    url: "/line/addLineNode",
    method: "post",
    data,
  });
}

export function delLineNodeTree(data) {
  //删除线路节点
  return request({
    url: "/line/delLineNode",
    method: "delete",
    params: data,
  });
}

export function updateLineNodeTree(data) {
  //修改线路节点
  return request({
    url: "/line/updateLineNode",
    method: "put",
    data,
  });
}

export function getChargingPileList(data) { //据站点获取电桩数据
  return request({
    url: "/chargingpile/list",
    method: "get",
    params: data,
  });
}