import request from "@/utils/request";

// 获取有效合作商列表
export function getPartnerEnableList(data) {
  return request({
    url: "/partner/enableList",
    method: "post",
    data,
  });
}
