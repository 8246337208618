import request from '@/utils/request'
// addAdvert
export function addAdvert(data) {
    return request({
        url: '/advert/addAdvert',
        method: 'post',
        data
    })
}
// editAdvert
export function editAdvert(data) {
    return request({
        url: '/advert/edit',
        method: 'put',
        data
    })
}
export function addAdverts(data) {
    return request({
        url: '/advert/addAdverts',
        method: 'post',
        data
    })
}

// advert/delAdvert
export function delAdvert(data) {
    return request({
        url: '/advert/delAdvert',
        method: 'delete',
        params: data
    })
}

// advert/getAdvertList
export function getAdvertList(data) {
    return request({
        url: '/advert/getAdvertList',
        method: 'get',
        params: data
    })
}

// advert/useAdvert
export function useAdvert(data) {
    return request({
        url: '/advert/useAdvert',
        method: 'get',
        params: data
    })
}