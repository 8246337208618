import request from '@/utils/request'
// GET /sysCard/getCardList
export function getPartnerList(data) {
    return request({
        url: '/partner/list',
        method: 'post',
        data

    })
}

export function getOperatorList(data) {
    return request({
        url: '/operator/getOperatorList',
        method: 'get',
        params: data

    })
}

export function addPartnerItem(data) {
    return request({
        url: '/partner/add',
        method: 'post',
        data

    })
}

export function editPartnerItem(data) {
    return request({
        url: '/partner/modify',
        method: 'post',
        data

    })
}

export function changeStateSwitch(data) {
    return request({
        url: '/partner/stateSwitch',
        method: 'post',
        data

    })
}