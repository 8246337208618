import request from '@/utils/request'

// GET /sysCard/getCardList
export function getCardList(data) {
    return request({
        url: '/sysCard/getCardList',
        method: 'get',
        params: data

    })
}

//POST /sysCard/addCard
export function addCard(data) {
    return request({
        url: '/sysCard/addCard',
        method: 'post',
        data

    })
}

//GET /sysCard/lossCard
export function lossCard(data) {
    return request({
        url: '/sysCard/lossCard',
        method: 'get',
        params: data

    })
}

//GET /sysCard/editCard
export function editCard(data) {
    return request({
        url: '/sysCard/editCard',
        method: 'post',
        data

    })
}

export function chongzhiCard(data) {
    return request({
        url: '/sysCard/rechargeCard',
        method: 'post',
        params: data
    })
}

//GET /sysCard/getUseRecods
export function getUseRecods(data) {
    return request({
        url: '/sysCard/getUseRecods',
        method: 'get',
        params: data

    })
}
///sysCard/getCardListByCustomerId
export function getCardListByCustomerId(data) {
    return request({
        url: '/sysCard/getCardListByCustomerId',
        method: 'get',
        params: data

    })
}
//POST /sysCard/transferAccounts
export function transferAccounts(data) {
    return request({
        url: '/sysCard/transferAccounts',
        method: 'post',
        params: data

    })
}