import request from "@/utils/request";

// 站点信息
export function getMemberPlots(data) {
  return request({
    url: "/member/plots",
    method: "post",
    data,
  });
}

// 会员新增
export function addMember(data) {
  return request({
    url: "/member/add",
    method: "post",
    data,
  });
}

// 会员详情
export function getMemberDetail(data) {
  return request({
    url: "/member/detail",
    method: "post",
    data,
  });
}

// 会员列表
export function getMemberList(data) {
  return request({
    url: "/member/list",
    method: "post",
    data,
  });
}

// 会员编辑
export function modifyMember(data) {
  return request({
    url: "/member/modify",
    method: "post",
    data,
  });
}

// 会员删除
export function deleteMember(data) {
  return request({
    url: "/member/delete",
    method: "post",
    data,
  });
}

// 手动停止会员
export function stopMember(data) {
  return request({
    url: "/member/stop",
    method: "post",
    data,
  });
}

// 会员移动端状态切换
export function showStateSwitch(data) {
  return request({
    url: "/member/showStateSwitch",
    method: "post",
    data,
  });
}

// 会员用户详情
export function getMemberUserList(data) {
  return request({
    url: "/member/user/list",
    method: "post",
    data,
  });
}

// 会员用户状态变更
export function setUserState(data) {
  return request({
    url: "/member/user/state",
    method: "post",
    data,
  });
}

// 用户优惠券列表
export function getMemberUserCouponList(data) {
  return request({
    url: "/member/user/coupon/list",
    method: "post",
    data,
  });
}
// 用户优惠券列表
export function getMemberQrCode(data) {
  return request({
    url: "/member/qrcode",
    method: "post",
    data,
  });
}
// 用户优惠券列表
export function plotsCheck(data) {
  return request({
    url: "/member/plotsCheck",
    method: "post",
    data,
  });
}
