import request from '@/utils/request'
// sysFeedback/doFeedBack'
export function doFeedBack(data) {
    return request({
        url: '/sysFeedback/doFeedBack',
        method: 'get',
        params: data

    })
}

// sysFeedback/getFeedBack
export function getFeedBack(data) {
    return request({
        url: '/sysFeedback/getFeedBack?id=' + data.id,
        method: 'post',
        data

    })
}
// sysFeedback / getFeedBackList
export function getFeedBackList(data) {
    return request({
        url: '/sysFeedback/getFeedBackList',
        method: 'get',
        params: data

    })
}