import request from "@/utils/request";

export function getPowerList(data) {
  return request({
    url: "/ammeter/page/list",
    method: "get",
    params: data,
  });
}

export function getOperatorList(data) {
  return request({
    url: "/operator/getOperatorList",
    method: "get",
    params: data,
  });
}

export function getPlotList(data) {
  return request({
    url: "/plot/getPlotList",
    method: "get",
    params: data,
  });
}

export function getAmmeterList(data) {
  return request({
    url: "/ammeter/list",
    method: "get",
    params: data,
  });
}

export function addAmmeter(data) {
  return request({
    url: "/ammeter/addAmmeter",
    method: "post",
    data,
  });
}

export function delAmmeterItem(data) {
  return request({
    url: "/ammeter/delAmmeter",
    method: "delete",
    params: data,
  });
}

export function updateAmmeterItem(data) {
  return request({
    url: "/ammeter/updateAmmeter",
    method: "put",
    data,
  });
}

// 根据指定电表获取监控数据
export function getAmmeterMonitor(params) {
  return request({
    url: "/ammeter/ammeter/monitor",
    method: "get",
    params,
  });
}
// 根据指定线路上的获取电表监控数据
export function getLineMonitor(data) {
  return request({
    url: "/ammeter/line/monitor",
    method: "get",
    params: data,
  });
}
