import request from '@/utils/request'
// addAdvert
export function addConfig(data) {
    return request({
        url: '/sysConfig/addConfig',
        method: 'post',
        data
    })
}
// editAdvert
export function editConfig(data) {
    return request({
        url: '/sysConfig/editConfig',
        method: 'post',
        data
    })
}
export function addAdverts(data) {
    return request({
        url: '/advert/addAdverts',
        method: 'post',
        data
    })
}


// advert/delAdvert
export function delConfig(data) {
    return request({
        url: '/sysConfig/deleteById',
        method: 'delete',
        params: data
    })
}

// file/getFileList
export function getConfigList(data) {
    return request({
        url: '/sysConfig/list',
        method: 'get',
        params: data
    })
}


export function getInfoById(data) {
    return request({
        url: '/sysConfig/getInfoById',
        method: 'get',
        params: data

    })
}

export function getInfoByKey(data) {
    return request({
        url: '/sysConfig/getInfoByKey',
        method: 'get',
        params: data

    })
}
