import request from '@/utils/request';
// 告警设置列表
export function getAlertSettingList(data) {
  return request({
    url: '/alert/setting/list',
    method: 'post',
    data,
  });
}

// 告警事件列表
export function getAlertList(data) {
  return request({
    url: '/alert/list',
    method: 'post',
    data,
  });
}

// 告警事件列表
export function getAlertDetail(eventId) {
  return request({
    url: `/alert/detail/${eventId}`,
    method: 'get',
  });
}

// 告警事件处理
export function alertHandle(data) {
  return request({
    url: `/alert/handle`,
    method: 'post',
    data,
  });
}

// 告警设置列表
export function getSettingList(data) {
  return request({
    url: `/alert/setting/list`,
    method: 'post',
    data,
  });
}

// 告警设置修改
export function modifySetting(data) {
  return request({
    url: `/alert/setting/modify`,
    method: 'post',
    data,
  });
}
