// registerUser
import request from '@/utils/request'

export function getRegisterUser(data) {
    return request({
        url: '/registerUser/getRegisterUser',
        method: 'get',
        params: data

    })
}

export function getChongzhiRecods(data) {
    return request({
        url: '/registerUser/getRechargeRecods',
        method: 'get',
        params: data
    })
}

export function bindMobile(data) {
    return request({
        url: '/registerUser/bindMobile',
        method: 'post',
        params: data
    })
}
export function refundOrder(data) {
    return request({
        url: '/registerUser/refundAmount',
        method: 'post',
        params: data
    })
}


export function getUserDetail(data) {
    return request({
        url: '/registerUser/userDetail',
        method: 'get',
        params: data
    })
}
export function changeUserState(data) {
    return request({
        url: '/registerUser/changeUserState',
        method: 'post',
        data
    })
}
export function getWalletWithdraw(data) {
    return request({
        url: '/wallet/withdraw',
        method: 'post',
        data
    })
}

export function getRewardGift(data) {
    return request({
        url: "/wallet/reward/gift",
        method: "post",
        data,
    });
}

// 用户车辆列表信息
export const getUserCars = (params) =>
    request({ url: `/car/userCars`, method: "get", params });
  
