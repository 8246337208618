import request from '@/utils/request';
// GET /sysCard/getCardList
export function getOrderList(data) {
  return request({
    url: '/sysOrder/getOrderList',
    method: 'get',
    params: data,
  });
}
//GET /sysOrder/getOrderList
//GET /sysOrder/getOrderData
export function getOrderData(data) {
  return request({
    url: '/sysOrder/getOrderData',
    method: 'get',
    params: data,
  });
}
export function getOrderDetail(data) {
  return request({
    url: '/sysOrder/v2/order-detail',
    method: 'get',
    params: data,
  });
}
export function getOrderTrace(data) {
  return request({
    url: '/sysOrder/v2/order-trace',
    method: 'get',
    params: data,
  });
}

export function refundOrder(data) {
  return request({
    url: '/sysOrder/refundOrder',
    method: 'get',
    params: data,
  });
}

export function ChangePort(data) {
  return request({
    url: '/sysOrder/portChange',
    method: 'get',
    params: data,
  });
}

// 退款接口
export function orderRefund(data) {
  return request({
    url: '/sysOrder/v3/order/refund',
    method: 'post',
    data,
  });
}
// 订单实时监测数据
export function getOrderRealTime(data) {
  return request({
    url: '/sysOrder/order-process',
    method: 'get',
    params: data,
  });
}
// 订单支付流水
export function getOrderPayFlow(data) {
  return request({
    url: '/sysOrder/v2/order-payFlow',
    method: 'get',
    params: data,
  });
}

// 订单退款重试
export function orderRefundReset(data) {
  return request({
    url: '/sysOrder/v3/order/refund/retry',
    method: 'post',
    params: data,
  });
}

// 电枪实时时监控数据
export function getConnectorMonitor(data) {
  return request({
    url: '/sysOrder/connector/monitor/data',
    method: 'get',
    params: data,
  });
}
