
import request from "@/utils/request";

export function getNewsList(data) { //分页获取消息数据
    return request({
      url: "/news/page/list",
      method: "get",
      params: data,
    });
  }

  export function getUserNewsList(data) { //分页获取用户消息数据
    return request({
      url: "/news/user/page/list",
      method: "get",
      params: data,
    });
  }

  export function getOperatorList(data) {
    return request({
      url: "/operator/getOperatorList",
      method: "get",
      params: data,
    });
  }

  export function addNews(data) { //添加消息
    return request({
      url: "/news/addNews",
      method: "post",
      data,
    });
  }

  export function readyViewNews(data) { //获取消息详情
    return request({
      url: "/news/viewNews",
      method: "get",
      params: data,
    });
  }

  export function updateNews(data) { //修改消息
    return request({
      url: "/news/updateNews",
      method: 'put',
      data
    });
  }

  export function delNews(data) { //删除消息
    return request({
      url: "/news/delNews",
      method: 'delete',
      params: data
    });
  }

  export function revokeNews(data) { //撤回消息
    return request({
      url: "/news/revokeNews",
      method: 'put',
      data
    });
  }