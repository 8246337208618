import request from "@/utils/request";
// 数据大屏-运营效率'
export function getOperationEfficiency(data) {
  return request({
    url: "/index/dashboard/operationEfficiency",
    method: "get",
    params: data,
  });
}

// 数据大屏-充电行为分布
export function getChargingBehavior(data) {
  return request({
    url: "/index/dashboard/chargingBehavior",
    method: "get",
    params: data,
  });
}

// 数据大屏-经营概览
export function getOverview(data) {
  return request({
    url: "/index/dashboard/overview",
    method: "get",
    params: data,
  });
}

// 数据大屏-功率趋势
export function getPowerTrend(data) {
  return request({
    url: "/index/dashboard/powerTrend",
    method: "get",
    params: data,
  });
}

// 数据大屏-经营趋势
export function getTrendRevenue(data) {
  return request({
    url: "/index/statistics/trendRevenue",
    method: "get",
    params: data,
  });
}
// 站点功率利用率排行
export function getPowerUtilization(data) {
  return request({
    url: "/index/statistics/powerUtilization",
    method: "get",
    params: data,
  });
}

// 数据大屏-车主充电频次
export function getChargingFrequency(data) {
  return request({
    url: "/index/dashboard/chargingFrequency",
    method: "get",
    params: data,
  });
}

// 数据大屏-经营效率
export function getBusinessEfficiency(data) {
  return request({
    url: "/index/dashboard/businessEfficiency",
    method: "get",
    params: data,
  });
}
