import request from '@/utils/request'
// addAdvert
export function addNews(data) {
    return request({
        url: '/sysNews/add',
        method: 'post',
        data
    })
}

export function editNews(data) {
    return request({
        url: '/sysNews/edit',
        method: 'post',
        data
    })
}


// advert/delAdvert
export function delNews(data) {
    return request({
        url: '/sysNews/deleteById',
        method: 'delete',
        params: data
    })
}

// file/getFileList
export function getNewsList(data) {
    return request({
        url: '/sysNews/list',
        method: 'get',
        params: data
    })
}


export function getInfoById(data) {
    return request({
        url: '/sysNews/getInfoById',
        method: 'get',
        params: data

    })
}

export function getInfoByKey(data) {
    return request({
        url: '/sysNews/getInfoByKey',
        method: 'get',
        params: data

    })
}
